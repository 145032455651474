import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import './resume.css';
import {Keywords} from "./keywords";

class Experience extends Component {

    render() {
        const {t} = this.props;
        const experience = [
            {
                "title": "Software Architect",
                "company": "Danske Bank",
                "start": 2021,
                "end": null,
                "description": t('resume.experience.danskebank-architect.description'),
                "technologies": [
                    Keywords.CSHARP,
                    Keywords.DDD,
                    Keywords.MICROSERVICES,
                    Keywords.CQRS,
                    Keywords.DOCKER,
                    Keywords.MSSQL,
                    Keywords.AZURE,
                    Keywords.RABBITMQ,
                    Keywords.AWS,
                    Keywords.OAUTH2,
                    Keywords.OIDC
                ]
            },
            {
                "title": "Senior Software Engineer",
                "company": "Danske Bank",
                "start": 2020,
                "end": null,
                "description": t('resume.experience.danskebank-engineer.description'),
                "technologies": [
                    Keywords.CSHARP,
                    Keywords.DDD,
                    Keywords.MICROSERVICES,
                    Keywords.CQRS,
                    Keywords.DOCKER,
                    Keywords.MSSQL,                
                    Keywords.AZURE,
                    Keywords.RABBITMQ
                ]
            },
            {
                "title": "Lead Software Engineer",
                "company": "Terma",
                "start": 2018,
                "end": 2020,
                "description": t('resume.experience.terma-tech-lead.description'),
                "technologies": [
                    Keywords.JAVA,
                    Keywords.PYTHON,
                    Keywords.JIRA,
                    Keywords.GERRIT,
                    Keywords.JENKINS,
                    Keywords.SOAP,
                    Keywords.REST,
                ]
            },
            {
                "title": "Software Engineer",
                "company": "Terma",
                "start": 2017,
                "end": 2018,
                "description": t('resume.experience.terma-engineer.description'),
                "technologies": [
                    Keywords.JAVA,
                    Keywords.CSHARP,
                    Keywords.PYTHON,
                    Keywords.GRPC,
                    Keywords.TENSORFLOW,
                    Keywords.DOCKER
                ]
            },
            {
                "title": "Software Engineer",
                "company": "Beumer Group",
                "start": 2016,
                "end": 2017,
                "description": t('resume.experience.beumer.description'),
                "technologies": [
                    Keywords.JAVA,
                    Keywords.MSSQL,
                    Keywords.GIT
                ]
            },
            {
                "title": t('resume.experience.au.title'),
                "company": t('resume.experience.au.company'),
                "start": 2014,
                "end": 2015,
                "description": t('resume.experience.au.description'),
                "technologies": [
                    Keywords.C,
                    Keywords.CSHARP,
                    Keywords.PYTHON
                ]
            },
            {
                "title": t('resume.experience.dgi-instructor.title'),
                "company": "DGI",
                "start": 2005,
                "end": 2014,
                "description": t('resume.experience.dgi-instructor.description')
            },
        ];


        return (
                <ul className="items">
                    {experience.map((item, i) =>
                        <li className="item" key={i}>
                            <div className="box animated" data-animationduration="1" data-animationtype="fadeInRightBig">
                                <div className="top">
                                    <p className="shorter bolder title">{item.title}</p>
                                    <p className="shorter highlight company">{item.company}</p>
                                    <p className="period">{item.start}</p>
                                </div>
                                <div className="bottom">
                                    <p>{item.description}</p>
                                    {item.technologies != null &&
                                    <div className="technologies">
                                        <ul>
                                            {item.technologies?.map((technology, i) => <li key={i}>{technology}</li>)}
                                        </ul>
                                    </div>
                                }
                                </div>
                                <i className="arrow fa fa-angle-down"/>
                            </div>
                            <div className="bullet animated" data-animationduration="1" data-animationtype="fadeIn"/>
                        </li>
                    )}
                </ul>
        )
    }
}

Experience.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Experience);
