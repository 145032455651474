import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import {translate} from 'react-switch-lang';
import './profile.css';

class Profile extends Component {

    render() {
        const {t} = this.props;
        return (
            <section id="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <p className="introduction">{t('profile.introduction')}</p>
                            </ScrollAnimation>
                        </div>
                        <div className="col-md-6 animated" data-animationduration="1" data-animationtype="fadeInUp">
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <p>{t('profile.professional')}</p>
                            </ScrollAnimation>
                        </div>
                        <div className="col-md-6 animated" data-animationduration="1" data-animationtype="fadeInUp">
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <p>{t('profile.personal')}</p>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="row strengths">
                        <div className="col-md-4">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="item structuring">
                                    <i className="las la-clipboard-list" />
                                    <h3 className="spaced short">{t('profile.strengths.first.title')}</h3>
                                    <p className="lowlight">{t('profile.strengths.first.description')}</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-md-4">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="item planning">
                                    <i className="las la-calendar"/>
                                    <h3 className="spaced short">{t('profile.strengths.second.title')}</h3>
                                    <p className="lowlight">{t('profile.strengths.second.description')}</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-md-4">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="item structuring">
                                    <i className="las la-clock" />
                                    <h3 className="spaced short">{t('profile.strengths.third.title')}</h3>
                                    <p className="lowlight">{t('profile.strengths.third.description')}</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

Profile.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Profile);
