import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import './resume.css';

class certificates extends Component {

    render() {
        const {t} = this.props;
        const certificates = [
            {
                "title": t('resume.certificates.system-architect-practitioner.title'),
                "institute": t('resume.certificates.system-architect-practitioner.institute'),
                "year": 2020,
                "description": t('resume.certificates.system-architect-practitioner.description'),
            },
            {
                "title": t('resume.certificates.it-architect-foundation.title'),
                "institute": t('resume.certificates.it-architect-foundation.institute'),
                "year": 2019,
                "description": t('resume.certificates.it-architect-foundation.description'),
            },
        ];


        return (
            <ul className="items">
                {certificates.map((item, i) =>
                    <li className="item" key={i}>
                        <div className="box animated" data-animationduration="1" data-animationtype="fadeInRightBig">
                            <div className="top">
                                <p className="shorter bolder">{item.title}</p>
                                <p className="shorter highlight company">{item.institute}</p>
                                <p className="period">{item.year}</p>
                            </div>
                            <div className="bottom">
                                <p>{item.description}</p>
                            </div>
                            <i className="arrow fa fa-angle-down"/>
                        </div>
                        <div className="bullet animated" data-animationduration="1" data-animationtype="fadeIn"/>
                    </li>
                )}
            </ul>
        )
    }
}

certificates.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(certificates);
