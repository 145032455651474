export const Keywords = {
    JAVA: "Java",
    CSHARP: "C#",
    PYTHON: "Python",
    C: "C",
    HTML: "HTML",
    CSS: "CSS",
    JAVASCRIPT: "Javascript",
    MSSQL: "MSSQL",
    AZURE: "Azure",
    DOCKER: "Docker",
    REST: "REST",
    SOAP: "SOAP",
    GRPC: "GRPC",
    TENSORFLOW: "TensorFlow",
    DDD: "DDD",
    GIT: "Git",
    RABBITMQ: "RabbitMQ",
    GERRIT: "Gerrit",
    JENKINS: "Jenkins",
    JIRA: "JIRA",
    MICROSERVICES: "Microservices",
    CQRS: "CQRS",
    AWS: "AWS",
    OIDC: "OpenID Connect",
    OAUTH2: "OAuth2"
};
