import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import './resume.css';

class Education extends Component {

    render() {
        const {t} = this.props;
        const education = [
            {
                "title": t('resume.education.cand.title'),
                "abbreviation": t('resume.education.cand.abbreviation'),
                "institute": t('resume.education.cand.institute'),
                "start": 2014,
                "end": 2016,
                "description": t('resume.education.cand.description'),
                "project": {
                    "title": t('resume.education.cand.project.title'),
                    "description": t('resume.education.cand.project.description'),
                }
            },
            {
                "title": t('resume.education.beng-business.title'),
                "abbreviation": t('resume.education.beng-business.abbreviation'),
                "institute": t('resume.education.beng-business.institute'),
                "start": 2013,
                "end": 2014,
                "description": t('resume.education.beng-business.description'),
                "project": {
                    "title": t('resume.education.beng-business.project.title'),
                    "description": t('resume.education.beng-business.project.description'),
                }
            },
            {
                "title": t('resume.education.beng.title'),
                "abbreviation": t('resume.education.beng.abbreviation'),
                "institute": t('resume.education.beng.institute'),
                "start": 2010,
                "end": 2013,
                "description": t('resume.education.beng.description'),
                "project": {
                    "title": t('resume.education.beng.project.title'),
                    "description": t('resume.education.beng.project.description'),
                }
            },
            {
                "title": t('resume.education.hhx.title'),
                "abbreviation": t('resume.education.hhx.abbreviation'),
                "institute": t('resume.education.hhx.institute'),
                "start": 2005,
                "end": 2008,
                "description": t('resume.education.hhx.description')
            }
        ];


        return (
            <ul className="items">
                {education.map((item, i) =>
                    <li className="item" key={i}>
                        <div className="box animated" data-animationduration="1" data-animationtype="fadeInRightBig">
                            <div className="top">
                                <p className="shorter bolder">{item.title}<span className="slim"> ({item.abbreviation})</span>
                                </p>
                                <p className="shorter highlight company">{item.institute}</p>
                                <p className="period">{item.start}</p>
                            </div>
                            <div className="bottom">
                                <p>{item.description}</p>
                                <p className="bolder">{item.project?.title}</p>
                                <p>{item.project?.description}</p>
                            </div>
                            <i className="arrow fa fa-angle-down"/>
                        </div>
                        <div className="bullet animated" data-animationduration="1" data-animationtype="fadeIn"/>
                    </li>
                )}
            </ul>
        )
    }
}

Education.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Education);
