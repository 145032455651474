import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setLanguage, translate, getLanguage} from 'react-switch-lang';
import portrait from './portrait.png';
import './home.css';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {isChecked: true};
        this.handleChecked = this.handleChecked.bind(this);
    }

    componentDidMount() {
        if (getLanguage() === 'en') {
            this.setState({
                isChecked: false
            });
        }
    }

    handleChecked () {
        this.setState({isChecked: !this.state.isChecked});
        let language = 'dk';
        if (this.state.isChecked) {
            language = 'en'
        }
        setLanguage(language);
    }

    render() {
        const {t} = this.props;
        return (
            <section id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="animated"><img className="portrait" src={portrait} alt={t('home.name')}/>
                            </div>
                            <div className="animated">
                                <h1 className="name">{t('home.name')}</h1>
                                <div className="line">
                                    <div className="dot"/>
                                </div>
                                <h2 className="title">{t('home.title')}</h2>
                            </div>
                            <div className="button-wrapper download">
                                <a target="_blank" href={"files/cv-"+ getLanguage() +".pdf"}
                                   className="button">{t('home.download-resume')}</a>
                            </div>
                            <div className="button-wrapper language">
                                <div className="toggle">
                                    <input type="checkbox" checked={this.state.isChecked} onChange={ this.handleChecked } />
                                    <span className="on">Dansk</span><span className="off">English</span>
                                    <div className="slider"><i className="las la-globe"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

Home.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Home);
