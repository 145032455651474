import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import './contact.css';
import $ from "jquery"
import ScrollAnimation from "react-animate-on-scroll";

class Contact extends Component {

    componentDidMount() {
        $('#contact-form-submit').click(function (e) {

            let $error = 0;

            const error = $('#contact-form .error');
            const success = $('#contact-form .success');

            const field_name = $("#contact-form-name");
            const field_email = $("#contact-form-email");
            const field_subject = $("#contact-form-subject");
            const field_message = $("#contact-form-message");
            // Anti spam field
            const field_website = $("#contact-form-website");

            const name = field_name.val();
            const email = field_email.val();
            const subject = field_subject.val();
            const message = field_message.val();
            const website = field_website.val();

            const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

            success.fadeOut(1000);

            if (website !== "") {
                return
            }

            if (name === "" || email === "" || subject === "" || message === "" || !regex.test(email)) {
                error.fadeIn(1000);
                $error = 1;

            } else {
                error.fadeOut(1000);
            }

            const dataString = 'name=' + name + '&email=' + email + '&subject=' + subject + '&message=' + message;

            if ($error === 0) {
                $.ajax({
                    type: "POST",
                    url: "http://www.steffanlildholdt.dk/php/send-mail.php",
                    data: dataString,
                    success: function (data) {
                        error.fadeOut(1000);
                        success.fadeIn(1000);

                        // Reset form fields
                        field_name.val("");
                        field_email.val("");
                        field_subject.val("");
                        field_message.val("");
                    }
                });
                return false;
            }
            e.preventDefault();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <section id="contact">
                <div className="container">
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <h1>{t('contact.headline')}</h1>
                    </ScrollAnimation>

                    <form id="contact-form" method="post">
                        <div className="form-fields">
                            <div className="row">
                                <div className="notification error col-md-12">
                                    <i className="fa fa-times-circle"/><p className="shorter">{t('contact.error')}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="notification success col-md-12">
                                    <i className="fa fa-check"/><p className="shorter">{t('contact.success')}</p>
                                </div>
                            </div>

                            <input type="hidden" name="website" id="contact-form-website" placeholder="Website"/>

                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="row">
                                    <div className="col-md-4 reset-vertical-spacing">
                                        <input className="required" type="text" name="name" id="contact-form-name"
                                               placeholder={t('contact.name')}/>
                                    </div>
                                    <div className="col-md-4 reset-vertical-spacing">
                                        <input className="required" type="text" name="email" id="contact-form-email"
                                               placeholder={t('contact.email')}/>
                                    </div>
                                    <div className="col-md-4 reset-vertical-spacing">
                                        <input className="required" type="text" name="subject" id="contact-form-subject"
                                               placeholder={t('contact.subject')}/>
                                    </div>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="row">
                                    <div className="col-md-12 reset-vertical-spacing">
                                        <textarea className="required" name="message" id="contact-form-message"
                                                  placeholder={t('contact.message')}/>
                                    </div>
                                    <div className="col-md-12 reset-vertical-spacing">
                                        <button type="submit" className="btn btn-default submit shadow-none"
                                                name="contact-form-submit" id="contact-form-submit">{t('contact.send')}
                                        </button>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}

Contact.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Contact);
